/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

// @mui
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Box, Grid, Card } from '@mui/material';

// components
import Iconify from '../../../components/iconify';

import API, { API_URL } from '../../../api';

export default function NewsUpdate({ news, resetPage }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState('');
  const [title_en, setTitleEn] = useState('');
  const [name, setName] = useState('');
  const [name_en, setNameEn] = useState('');
  const [subscriber, setSubscriber] = useState('');
  const [subscriber_en, setSubscriberEn] = useState('');
  const [date, setDate] = useState('');
  const [location, setLocation] = useState('');
  const [location_en, setLocationEn] = useState('');

  const [paths, setPaths] = useState([]);
  const [headerPaths, setHeaderPaths] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/*', // You can accept any image type here
    multiple: true, // Allow multiple files to be selected
    maxFiles: 10, // Set a maximum number of files (if needed)
  });

  console.log(news);
  useEffect(() => {
    const { date, headerImg, images, location, location_en, name_en, subscriber, subscriber_en, title, title_en } =
      news;

    setOpen(false);
    setTitle(title);
    setTitleEn(title_en);
    setName(name);
    setNameEn(name_en);
    setSubscriber(subscriber);
    setSubscriberEn(subscriber_en);
    setDate(date);
    setLocation(location);
    setLocationEn(location_en);
    setSelectedImage(null);
    setPaths([]);
    setHeaderPaths(headerImg);
    if (images) {
      const imageUrls = images.map((image) => `${image}`);
      setPaths(imageUrls);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [news]);

  const {
    acceptedFiles: acceptedPDFFiles,
    getRootProps: getPDFRootProps,
    getInputProps: getPDFInputProps,
  } = useDropzone({
    accept: {},
    maxFiles: 1,
  });
  const handleHeaderImageDelete = async (image) => {
    try {
      setLoading(true);
      const data = { imageUrl: image, type: '1' };
      const response = await API.delete(`/project`, {
        data,
      });
      const {
        data: { success, message },
      } = response;
      if (success === true) {
        setHeaderPaths(null);
        toast.success('Амжилттай', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleImageDelete = async (key, image) => {
    try {
      setLoading(true);
      const data = { imageUrl: image };
      const response = await API.delete(`/project`, {
        data,
      });
      const {
        data: { success, message },
      } = response;
      if (success === true) {
        const updatedPaths = [...paths];
        // Remove the item at sthe specified key/index
        updatedPaths.splice(key, 1);
        // Update the state variable 'paths' with the modified array
        setPaths(updatedPaths);
        toast.success('Амжилттай', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const PDFFiles = acceptedPDFFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const imageFiles = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  const handleSubmit = async () => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const newsData = new FormData();

    acceptedFiles.forEach((file) => {
      newsData.append('images', file);
    });

    newsData.append('title', title);
    newsData.append('subscriber', subscriber);
    newsData.append('location', location);
    newsData.append('date', date);
    newsData.append('images', paths);

    newsData.append('title_en', title_en);
    newsData.append('name_en', name_en);
    newsData.append('subscriber_en', subscriber_en);
    newsData.append('location_en', location_en);

    newsData.append('headerImg', headerPaths === null ? acceptedPDFFiles[0] : headerPaths);
    try {
      setLoading(true);
      const response = await API.put(`/project/${news._id}`, newsData, config);
      const {
        data: { success, message },
      } = response;
      if (success === true) {
        resetPage();
        handleClose();
        toast.success('Амжилттай', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  return (
    <>
      <Button
        fullWidth
        sx={{ justifyContent: 'start' }}
        startIcon={<Iconify icon="eva:checkmark-fill" />}
        onClick={handleClickOpen}
      >
        Засах
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={false}>
        <DialogTitle>Төсөл засах</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <TextField
                sx={{
                  mb: 1,
                }}
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                label="Гарчиг"
                type="text"
                fullWidth
                autoFocus
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={title_en}
                onChange={(event) => setTitleEn(event.target.value)}
                label="Гарчиг EN"
                type="text"
                fullWidth
                autoFocus
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={name}
                onChange={(event) => setName(event.target.value)}
                label="Нэр"
                type="text"
                fullWidth
                autoFocus
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={name_en}
                onChange={(event) => setNameEn(event.target.value)}
                label="Нэр EN"
                type="text"
                fullWidth
                autoFocus
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={subscriber}
                onChange={(event) => setSubscriber(event.target.value)}
                label="Захиалагч"
                type="text"
                fullWidth
                autoFocus
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={subscriber_en}
                onChange={(event) => setSubscriberEn(event.target.value)}
                label="Захиалагч EN"
                type="text"
                fullWidth
                autoFocus
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={location}
                onChange={(event) => setLocation(event.target.value)}
                label="Хаяг"
                type="text"
                fullWidth
                autoFocus
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={location_en}
                onChange={(event) => setLocationEn(event.target.value)}
                label="Хаяг EN"
                type="text"
                fullWidth
                autoFocus
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={date}
                onChange={(event) => setDate(event.target.value)}
                label="Он"
                type="text"
                fullWidth
                autoFocus
              />
            </Grid>
            <Grid item xs={4}>
              <Card variant="outlined" sx={{ mb: 1 }}>
                <Box m={1}>
                  <div
                    style={{
                      margin: '0.5rem 0.75rem ',
                    }}
                  >
                    <h4> Зурагнууд</h4>
                  </div>

                  <Box sx={{ borderRadius: 1, border: 'dotted', borderColor: 'grey.500', p: 2, textAlign: 'center' }}>
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} multiple />
                      <p>Зураг нэмэх бол зурган файлыг энд чирж, эсвэл энд товшино уу.</p>
                      <em>(Зөвхөн *.jpeg болон *.png зургийг хүлээн авна)</em>
                    </div>
                  </Box>
                  {imageFiles.length > 0 ? (
                    <aside style={{ paddingBottom: '10px' }}>
                      <h4>Шинээр нэмэх зурагнууд:</h4>
                      <ul>{imageFiles}</ul>
                    </aside>
                  ) : null}
                  <div
                    style={{
                      margin: '0.5rem 0.75rem ',
                    }}
                  >
                    <h4> Хуучин зураг</h4>
                  </div>
                  {paths.length > 0 ? (
                    paths.map((path, key) => (
                      <Box
                        key={path}
                        sx={{
                          border: '1px solid #ebebeb',
                          borderRadius: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <img src={`${API_URL}${path}`} alt={imageFiles[key] || 'no photo'} width="full" height="full" />
                        <Button sx={{ color: 'error.main' }} onClick={() => handleImageDelete(key, path)}>
                          Зураг устгах
                        </Button>
                      </Box>
                    ))
                  ) : (
                    <Box sx={{ borderRadius: 1, border: 'dotted', borderColor: 'grey.500', p: 2, textAlign: 'center' }}>
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p>Зурган файлыг энд чирж, эсвэл энд товшино уу.</p>
                        <em>(Зөвхөн *.jpeg болон *.png зургийг хүлээн авна)</em>
                      </div>
                    </Box>
                  )}
                  <div
                    style={{
                      margin: '0.5rem 0.75rem ',
                    }}
                  >
                    <h4>Төслийн толгой зураг</h4>
                  </div>
                  {headerPaths === null ? (
                    <Box sx={{ borderRadius: 1, border: 'dotted', borderColor: 'grey.500', p: 2, textAlign: 'center' }}>
                      <div {...getPDFRootProps({ className: 'dropzone' })}>
                        <input {...getPDFInputProps()} />
                        <p>Зурган файлыг энд чирж, эсвэл энд товшино уу.</p>
                        <em>(Зөвхөн *.jpeg болон *.png зургийг хүлээн авна)</em>
                      </div>
                      {PDFFiles.length > 0 && (
                        <aside>
                          <h4>Толгой зураг:</h4>
                          <p>{PDFFiles}</p>
                        </aside>
                      )}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        border: '1px solid #ebebeb',
                        borderRadius: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <img
                        src={`${API_URL}${headerPaths}`}
                        alt={headerPaths || 'no photo'}
                        width="full"
                        height="full"
                      />
                      <Button sx={{ color: 'error.main' }} onClick={() => handleHeaderImageDelete(headerPaths)}>
                        Зураг устгах
                      </Button>
                    </Box>
                  )}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
