/* eslint-disable no-unused-vars */
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { format } from 'date-fns';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { NewsListHead, NewsModal, NewsUpdate } from '../sections/@dashboard/news';
// api
import API from '../api';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'index', label: '№', alignRight: false },
  { id: 'title', label: 'Гарчиг', alignRight: false },
  { id: 'name', label: 'Нэр', alignRight: false },
  { id: 'subscriber', label: 'Захиалагч', alignRight: false },
  { id: 'location', label: 'Хаяг', alignRight: false },
  { id: 'date', label: 'Он', alignRight: false },
  { id: 'title_en', label: 'Гарчиг EN', alignRight: false },
  { id: 'name_en', label: 'Нэр EN', alignRight: false },
  { id: 'subscriber_en', label: 'Захиалагч EN', alignRight: false },
  { id: 'location_en', label: 'Хаяг EN', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function NewsPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [loading, setLoading] = useState(false);

  // const [selectedCategory, setSelectedCategory] = useState({});

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [project, setProject] = useState([]);

  const [selectedProject, setSelectedProject] = useState({});

  const [totalNewsCount, setTotalNewsCount] = useState(0);

  const [payload, setPayload] = useState(null);

  const fetchNews = async ({ page = 1, limit = 20, payload }) => {
    const response = await API.get(`/project?page=${page}&limit=${limit}`, {});
    const {
      data: {
        data,
        pagination: { total },
      },
    } = response;
    setProject(data);
    setTotalNewsCount(total);
  };

  const resetPage = () => {
    setPage(0);
    setPayload(null);
    setSelectedProject({});
    setOpen(false);
    try {
      setLoading(true);
      fetchNews({ page, limit: rowsPerPage });
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      setLoading(true);

      fetchNews({ payload, page: page + 1, limit: rowsPerPage });
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleDelete = async () => {
    // todo: call api
    try {
      const response = await API.delete(`/project/${selectedProject._id}`);
      const {
        data: { success, message },
      } = response;

      if (success === true) {
        handleCloseMenu();
        toast.success(`${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        fetchNews(page, rowsPerPage);
      } else {
        toast.error(`${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleOpenMenu = (event, selectedProject) => {
    setOpen(event.currentTarget);
    setSelectedProject(selectedProject);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const onSelectCategory = (category) => {
    setPage(0);
    // setSelectedCategory(category);
  };

  return (
    <>
      <Helmet>
        <title> Төслүүд </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Төслүүд
          </Typography>

          <NewsModal resetPage={resetPage} />
        </Stack>

        <Card>
          {loading ? (
            <CircularProgress />
          ) : (
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <NewsListHead headLabel={TABLE_HEAD} rowCount={project.length} />
                  <TableBody>
                    {project.length > 0 ? (
                      project.map((row, index) => {
                        const {
                          title,
                          name,
                          subscriber,
                          location,
                          date,
                          title_en,
                          name_en,
                          subscriber_en,
                          location_en,
                        } = row;

                        return (
                          <TableRow
                            hover
                            key={index}
                            sx={{
                              position: 'relative',
                            }}
                          >
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap px={2}>
                                  {index + 1}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap px={2}>
                                  {title}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" px={2}>
                                  {name || '---'}
                                </Typography>
                              </Stack>
                            </TableCell>
                            {/* <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {n_content ? (
                                    <>
                                      {n_content && n_content?.slice(0, 15)} {n_content?.length > 15 && '...'}
                                    </>
                                  ) : (
                                    '---'
                                  )}
                                </Typography>
                              </Stack>
                            </TableCell> */}
                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {subscriber || '---'}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {location || '---'}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" px={2}>
                                  {date || '---'}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" px={2}>
                                  {title_en || '---'}
                                </Typography>
                              </Stack>
                            </TableCell>
                            {/* <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2">{n_desc || '...'}</Typography>
                              </Stack>
                            </TableCell> */}
                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {name_en || '---'}
                                </Typography>
                              </Stack>
                            </TableCell>
                            {/* <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {updated_at ? format(new Date(updated_at), 'yyyy-MM-dd') : '---'}
                                </Typography>
                              </Stack>
                            </TableCell> */}
                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {subscriber_en || '---'}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {location_en || '---'}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell align="right">
                              <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Өгөгдөл олдсонгүй
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          )}

          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={totalNewsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <NewsUpdate news={selectedProject} resetPage={resetPage} />
        </MenuItem>

        <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Устгах
        </MenuItem>
      </Popover>
    </>
  );
}
