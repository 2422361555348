/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// @mui
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

// components
import Iconify from '../../../components/iconify';

import API from '../../../api';

const filter = createFilterOptions();

// legacy: used before
// const ROLES = [
//   { label: 'Super admin', value: 1 },
//   { label: 'Admin', value: 2 },
// ];

export default function AdminUpdate({ resetPage, selectedAdmin, divisions }) {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [role, setRole] = useState();

  const [selectedDiv, setSelectedDiv] = useState(null);

  useEffect(() => {
    const { a_username, role } = selectedAdmin;
    // console.log(selectedAdmin);
    setRole(role);
  }, [selectedAdmin]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const {
        data: { success, message },
      } = await API.put(`/admin/acc`, {
        a_id: selectedAdmin.a_id,
        d_id: selectedDiv.d_id || selectedAdmin.d_id,
        role,
      });
      if (success === 1) {
        resetPage();
        handleClose();
        toast.success('Амжилттай шинэчлэгдлээ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(`Хүсэлт амжилтгүй ${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
      toast.error(`Хүсэлт амжилтгүй дахин оролдоно уу ${error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        fullWidth
        sx={{ justifyContent: 'start' }}
        startIcon={<Iconify icon="eva:checkmark-fill" />}
        onClick={handleClickOpen}
      >
        Засах
      </Button>
      <Dialog fullWidth maxWidth={'xs'} open={open} onClose={handleClose}>
        <DialogTitle>Засах</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Админы төрөл</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={role}
                  label="Админы төрөл"
                  onChange={(event) => setRole(event.target.value)}
                >
                  <MenuItem value={2}>Админ (Идэвхтэй)</MenuItem>
                  <MenuItem value={3}>Идэвхгүй</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                value={selectedDiv?.d_name}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setSelectedDiv({
                      d_name: newValue,
                    });
                  } else {
                    setSelectedDiv(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  const isExisting = options.some((option) => inputValue === option.d_name);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      d_name: `${inputValue}`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                handleHomeEndKeys
                id="free-solo-with-text-division"
                options={divisions}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.d_name;
                }}
                renderOption={(props, option) => <li {...props}>{option.d_name || '--- Өгөгдөл алдаатай ---'}</li>}
                sx={{ width: 1 }}
                freeSolo
                fullWidth
                renderInput={(params) => <TextField {...params} label="Харьяа хэлтэс сонгох" />}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
