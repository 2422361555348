/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';

// @mui
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
  Grid,
  Card,
  Avatar,
  CircularProgress,
  TablePagination,
} from '@mui/material';
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

// components
import Iconify from '../../../components/iconify';

import API, { API_URL } from '../../../api';

export default function NewsModal({ resetPage }) {
  const [open, setOpen] = useState(false);
  const [openImageSelect, setOpenImageSelect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [title_en, setTitleEn] = useState('');
  const [name, setName] = useState('');
  const [name_en, setNameEn] = useState('');
  const [subscriber, setSubscriber] = useState('');
  const [subscriber_en, setSubscriberEn] = useState('');
  const [date, setDate] = useState('');
  const [location, setLocation] = useState('');
  const [location_en, setLocationEn] = useState('');

  const [paths, setPaths] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setPaths(acceptedFiles.map((file) => URL.createObjectURL(file)));
    },
    [setPaths]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop,
  });

  const {
    acceptedFiles: acceptedPDFFiles,
    getRootProps: getPDFRootProps,
    getInputProps: getPDFInputProps,
  } = useDropzone({
    accept: {},
    maxFiles: 100,
  });

  const imageFiles = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const PDFFiles = acceptedPDFFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleClickOpen = () => {
    setOpen(true);
  };
  const removeAll = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    acceptedPDFFiles.length = 0;
    acceptedPDFFiles.splice(0, acceptedFiles.length);
  };
  const handleClose = () => {
    setTitle('');
    setTitleEn('');
    setName('');
    setNameEn('');
    setSubscriber('');
    setSubscriberEn('');
    setDate('');
    setLocation('');
    setLocationEn('');
    removeAll();
    setOpen(false);
    setSelectedImage(null);
    setPaths([]);
  };

  const showError = (field) => {
    toast.error(`${field}  оруулна уу!`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  };

  const handleSubmit = async () => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const newsData = new FormData();

    if (!title) {
      showError('Гарчиг');
      return;
    }

    // Push each selected image file to the FormData object
    acceptedFiles.forEach((file) => {
      newsData.append('images', file);
    });

    newsData.append('title', title);
    newsData.append('subscriber', subscriber);
    newsData.append('location', location);
    newsData.append('date', date);

    newsData.append('title_en', title_en);
    newsData.append('name_en', name_en);
    newsData.append('subscriber_en', subscriber_en);
    newsData.append('location_en', location_en);

    newsData.append('headerImg', acceptedPDFFiles[0]);

    try {
      setLoading(true);
      const response = await API.post(`/project`, newsData, config);
      const {
        data: { success, message },
      } = response;
      if (success === true) {
        resetPage();
        handleClose();
        toast.success('Амжилттай', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };
  const removeImage = (index) => {
    const updatedPaths = [...paths];
    updatedPaths.splice(index, 1); // Remove the image at the specified index
    setPaths(updatedPaths);
  };
  const upImg = (blobInfo, _progress) =>
    new Promise((resolve, _reject) => {
      blobToBase64(blobInfo.blob()).then((res) => {
        resolve(res);
      });
    });

  const handlePickImage = () => {
    setOpenImageSelect(true);
  };

  const handleSelectImage = (data) => {
    // console.log(data);
    setSelectedImage(data);
    setOpenImageSelect(false);
    setPaths([]);
    setPaths([`${API_URL}${data.file_path}`]);
  };

  return (
    <div>
      <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
        Нэмэх
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={false}>
        <DialogTitle>Төсөл нэмэх</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <TextField
                sx={{
                  mb: 1,
                }}
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                label="Гарчиг"
                type="text"
                fullWidth
                autoFocus
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={title_en}
                onChange={(event) => setTitleEn(event.target.value)}
                label="Гарчиг EN"
                type="text"
                fullWidth
                autoFocus
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={name}
                onChange={(event) => setName(event.target.value)}
                label="Нэр"
                type="text"
                fullWidth
                autoFocus
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={name_en}
                onChange={(event) => setNameEn(event.target.value)}
                label="Нэр EN"
                type="text"
                fullWidth
                autoFocus
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={subscriber}
                onChange={(event) => setSubscriber(event.target.value)}
                label="Захиалагч"
                type="text"
                fullWidth
                autoFocus
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={subscriber_en}
                onChange={(event) => setSubscriberEn(event.target.value)}
                label="Захиалагч EN"
                type="text"
                fullWidth
                autoFocus
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={location}
                onChange={(event) => setLocation(event.target.value)}
                label="Хаяг"
                type="text"
                fullWidth
                autoFocus
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={location_en}
                onChange={(event) => setLocationEn(event.target.value)}
                label="Хаяг EN"
                type="text"
                fullWidth
                autoFocus
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={date}
                onChange={(event) => setDate(event.target.value)}
                label="Он"
                type="text"
                fullWidth
                autoFocus
              />
            </Grid>
            <Grid item xs={4}>
              <Card variant="outlined" sx={{ mb: 1 }}>
                <Box m={1}>
                  <div
                    style={{
                      margin: '0.5rem 0.75rem ',
                    }}
                  >
                    Төслийн холбоотой зураг
                  </div>
                  {paths.length > 0 ? (
                    paths.map((path, index) => (
                      <Box
                        key={path}
                        sx={{
                          border: '1px solid #ebebeb',
                          borderRadius: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          marginBottom: 1,
                        }}
                      >
                        <img src={path} alt={` ${index}`} width="100" height="100" />
                        <Button onClick={() => removeImage(index)}>Remove</Button> {/* Add removeImage function */}
                      </Box>
                    ))
                  ) : (
                    <Box sx={{ borderRadius: 1, border: 'dotted', borderColor: 'grey.500', p: 2, textAlign: 'center' }}>
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} multiple /> {/* Add 'multiple' attribute here */}
                        <p>Зурган файлыг энд чирж, эсвэл энд товшино уу.</p>
                        <em>(Зөвхөн *.jpeg болон *.png зургийг хүлээн авна)</em>
                      </div>
                    </Box>
                  )}
                  <div
                    style={{
                      margin: '0.5rem 0.75rem ',
                    }}
                  >
                    Төслийн толгой зураг
                  </div>
                  <Box sx={{ borderRadius: 1, border: 'dotted', borderColor: 'grey.500', p: 2, textAlign: 'center' }}>
                    <div {...getPDFRootProps({ className: 'dropzone' })}>
                      <input {...getPDFInputProps()} /> {/* Remove 'multiple' attribute here */}
                      <p>Зурган файлыг энд чирж, эсвэл энд товшино уу.</p>
                      <em>(Зөвхөн *.pdf файлыг хүлээн авна)</em>
                      <em>(Дахин дарж солино уу.)</em>
                    </div>
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export const ImageSelector = ({ handleSelectImage }) => {
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);

  const [total, setTotal] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [images, setImages] = useState([]);

  const fetchImages = async (page = 1, limit = 20) => {
    const response = await API.get(`/upload/search?page=${page}&limit=${limit}&text=&file_type=image`);
    const {
      data: {
        data,
        pagination: { total },
      },
    } = response;
    setTotal(total);
    setImages(data);
  };

  useEffect(() => {
    try {
      setLoading(true);
      fetchImages(page, rowsPerPage);
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // const handleSelectImage = (data) => {
  //   console.log(data);
  //   setSelectedImage(data);
  // };

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {loading && <CircularProgress />}

        <Grid container alignItems="center" justifyContent="center" m={5} p={5} gap={1}>
          {images && images.length > 0 ? (
            <>
              {images.map((imageData, key) => {
                const { file_path, file_id } = imageData;
                return (
                  <Grid
                    key={key}
                    item
                    xs={2}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #ebebeb',
                      ':hover': {
                        cursor: 'pointer',
                        opacity: 80,
                        bg: '#ebebeb',
                      },
                    }}
                    p={1}
                    onClick={() => handleSelectImage(imageData)}
                  >
                    <Avatar
                      sx={{ bgcolor: '#ebebeb', width: '100px', height: '100px' }}
                      alt="img"
                      src={`${API_URL}${file_path}`}
                      variant="square"
                    />
                    <Box sx={{ textAlign: 'center', fontSize: '12px' }}>{file_path.split('/').pop()}</Box>
                  </Grid>
                );
              })}
            </>
          ) : (
            <Grid item xs={3} sx={{ textAlign: 'center' }}>
              No Images
            </Grid>
          )}
        </Grid>

        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
};
