// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const userNavConfig = [
  // {
  //   title: 'Самбар',
  //   path: '/dashboard/app',
  //   icon: icon('ic_analytics'),
  // },
  {
    title: 'Мэдээ',
    path: '/dashboard/news',
    icon: icon('ic_blog'),
  },
  {
    title: 'Харьяа Хэлтэс',
    path: '/dashboard/admin-division',
    icon: icon('ic_analytics'),
  },
];

export default userNavConfig;
