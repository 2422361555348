/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// @mui
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

// components
import Iconify from '../../../components/iconify';

import API from '../../../api';

const ROLES = [
  { label: 'Super admin', value: 1 },
  { label: 'Admin', value: 2 },
];

export default function AdminUpdate({ resetPage, selectedFeedback }) {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [newPassword, setPassword] = useState('');
  const [retry, setRetry] = useState('');
  const [role, setRole] = useState();
  useEffect(() => {
    const { username, role, _id } = selectedFeedback;
    setRole(role);
  }, [selectedFeedback]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (newPassword !== retry) {
      toast.error(`Адилхан нууц үг оруулна уу`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }

    try {
      setLoading(true);
      const {
        data: { success, message },
      } = await API.put(`/auth/${selectedFeedback._id}`, {
        username: selectedFeedback.username,
        password: newPassword,
      });
      if (success === true) {
        resetPage();
        handleClose();
        toast.success('Амжилттай шинэчлэгдлээ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(`Хүсэлт амжилтгүй ${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
      toast.error(`Хүсэлт амжилтгүй дахин оролдоно уу ${error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        fullWidth
        sx={{ justifyContent: 'start' }}
        startIcon={<Iconify icon="eva:checkmark-fill" />}
        onClick={handleClickOpen}
      >
        Нууц үг солих
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Нууц үг солих</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <TextField
            sx={{
              mt: 1,
            }}
            value={newPassword}
            onChange={(event) => setPassword(event.target.value)}
            label="Шинэ нууц үг"
            type="text"
            fullWidth
          />
          <TextField
            sx={{
              my: 1,
            }}
            value={retry}
            onChange={(event) => setRetry(event.target.value)}
            label="Нууц үг давтах"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
