import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Popover,
  Avatar,
  Paper,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  IconButton,
  CircularProgress,
  Grid,
} from '@mui/material';
import { toast } from 'react-toastify';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { BannerCreate, BannerUpdate, BannerDelete } from '../sections/@dashboard/banner';
// api
import API, { API_URL } from '../api';

// ----------------------------------------------------------------------

export default function BannerPage() {
  const [open, setOpen] = useState(null);

  const [loading, setLoading] = useState(false);

  const [selectedPerson, setSelectedPerson] = useState({});

  const [people, setPeople] = useState([]);

  const fetchBanner = async () => {
    const response = await API.get('/partner');
    const {
      data: { data, success, message },
    } = response;
    if (success === true) setPeople(data);
    else {
      toast.error(`Хүсэлт амжилтгүй ${message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  useEffect(() => {
    try {
      setLoading(true);
      fetchBanner();
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleOpenMenu = (event, data) => {
    setSelectedPerson(data);
    setOpen(event.currentTarget);
  };

  const resetPage = () => {
    setOpen(null);
    setSelectedPerson({});
    fetchBanner();
  };

  return (
    <>
      <Helmet>
        <title> </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Хамтрагч компани
          </Typography>
          <BannerCreate resetPage={resetPage} />
        </Stack>

        <Scrollbar>
          <Card>
            <Grid container>
              <Grid item xs={6} />
              <Grid item xs={6}>
                {loading && <CircularProgress />}
              </Grid>
            </Grid>
            <TableContainer sx={{ minWidth: 400 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>№</TableCell>
                    <TableCell>Зураг</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {people.length > 0 ? (
                    people.map((rowData, index) => {
                      const { _id, logo } = rowData;
                      return (
                        <TableRow key={_id} hover>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap px={2}>
                                {index + 1}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle" noWrap>
                                <Avatar
                                  sx={{ bgcolor: '#ebebeb' }}
                                  alt="img"
                                  src={`${API_URL}/${logo}`}
                                  variant="square"
                                />
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => handleOpenMenu(event, rowData)}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Өгөгдөл олдсонгүй
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Scrollbar>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* <MenuItem>
          <BannerUpdate selectedPerson={selectedPerson} resetPage={resetPage} />
        </MenuItem> */}

        <MenuItem sx={{ color: 'error.main' }}>
          <BannerDelete id={selectedPerson._id} resetPage={resetPage} />
        </MenuItem>
      </Popover>
    </>
  );
}
