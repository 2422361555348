import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import SimpleLayout from './layouts/simple';
import DashboardLayout from './layouts/dashboard';
//
import Page404 from './pages/Page404';
import NewsPage from './pages/NewsPage';
import LoginPage from './pages/LoginPage';
import BannerPage from './pages/BannerPage';
import AdminPage from './pages/AdminPage';
import FilePage from './pages/FilePage';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/admin" />, index: true },
        { path: 'banner', element: <BannerPage /> },
        { path: 'news', element: <NewsPage /> },
        { path: 'admin', element: <AdminPage /> },
        { path: 'file', element: <FilePage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/admin" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
